
import './App.css';
import * as colors from '../src/Styles/Colors'
import '../src/Styles/media_queries.css'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { Suspense, lazy, useEffect, useState } from 'react';
import { get_client_details } from './Components/ClientDetails';
import ClipLoader from "react-spinners/ClipLoader";
import Modal from 'react-modal';
import { css } from "@emotion/react";
import { initializeLocalStorage, set } from './LocalStorage/LocalStorage';
import WebFont from 'webfontloader';
import importRetry from './importRetry';

const Signup = lazy(() => importRetry(() => import('./Screens/Signup')));
const Signin = lazy(() => importRetry(() => import('./Screens/Signin')));
const LetsBegin = lazy(() => importRetry(() => import('./Screens/LetsBegin')));
const JumbledWords = lazy(() => importRetry(() => import('./Screens/ScrambleWordGame/JumbledWords')));
const QuestRating = lazy(() => importRetry(() => import('./Screens/QuestScreens/QuestRating')));
const QuestComment = lazy(() => importRetry(() => import('./Screens/QuestScreens/QuestComment')));
const QuestUpload = lazy(() => importRetry(() => import('./Screens/QuestScreens/QuestUpload')));
const Coupons = lazy(() => importRetry(() => import('./Screens/Coupons')));
const Points = lazy(() => importRetry(() => import('./Screens/Points')));
const Profile = lazy(() => importRetry(() => import('./Screens/Profile')));
const ErrorPage = lazy(() => importRetry(() => import('./Screens/ErrorPage')));
const DiscountUser = lazy(() => importRetry(() => import('./Screens/DiscountUser')));


const Game = lazy(() => importRetry(() => import('./Screens/Tic_tac_game/Game')));
const Subscribe2 = lazy(() => importRetry(() => import('./Screens/Subscribe2')));
const Discount = lazy(() => importRetry(() => import('./Screens/Discount')));
const Jigsaw = lazy(() => importRetry(() => import('./Screens/JigsawPuzzle/Jigsaw')));

function App() {
  Modal.setAppElement('#root');
  const [loading, setLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(true);

  const override = css`margin-top: 60% ; `;

  useEffect(() => {
    initializeLocalStorage()
    const url = window.location.search;
    const urlParams = new URLSearchParams(url);
    const client_unique_id = urlParams.get('details');
    if (client_unique_id) {
      localStorage.setItem('client_unique_id', client_unique_id);
    }

    get_client_details().then(result => {
      if (result.success === true) {
        var data = result.data
        var fonts = []
        if (data.font_family_head1) { fonts.push(data.font_family_head1) }
        if (data.font_family_head2) { fonts.push(data.font_family_head2) }
        if (data.font_family_para) { fonts.push(data.font_family_para) }
        if (fonts.length > 0) {
          WebFont.load({
            google: {
              families: fonts
            }
          });
        }
        setLoading(false)
      }
    })
  }, [])

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };


  return (
    <>
      <Suspense fallback={<>...</>}>
        <Modal
          isOpen={isOpen}
          className="w3-card w3-center float-right-class blue-border-cards"
          contentLabel="Example Modal">
          {loading ? <>
            <ClipLoader color={colors.input_color} loading={loading} size={30} css={override} style={{ marginTop: '70%' }} />
          </>
            :
            <>
              <Router>
                <Routes>
                  <Route exact path="/" element={<Signup toggleModal={toggleModal} />} />
                  <Route exact path="/signin" element={<Signin toggleModal={toggleModal} />} />
                  <Route exact path="/letsbegin" element={<LetsBegin toggleModal={toggleModal} />} />
                  <Route exact path="/tic_tac_game" element={<Game toggleModal={toggleModal} />} />
                  <Route exact path="/jumbled_words" element={<JumbledWords toggleModal={toggleModal} />} />
                  <Route exact path="/jigsaw" element={<Jigsaw toggleModal={toggleModal} />} />
                  <Route exact path="/quest_rating" element={<QuestRating toggleModal={toggleModal} />} />
                  <Route exact path="/quest_comment" element={<QuestComment toggleModal={toggleModal} />} />
                  <Route exact path="/quest_upload" element={<QuestUpload toggleModal={toggleModal} />} />
                  <Route exact path="/subscribe2" element={<Subscribe2 toggleModal={toggleModal} />} />
                  <Route exact path="/coupon" element={<Coupons toggleModal={toggleModal} />} />
                  <Route exact path="/discount" element={<Discount toggleModal={toggleModal} />} />
                  <Route exact path="/discount_user" element={<DiscountUser toggleModal={toggleModal} />} />
                  <Route exact path="/points" element={<Points toggleModal={toggleModal} />} />
                  <Route exact path="/points/:points_earned" element={<Points toggleModal={toggleModal} />} />
                  <Route exact path="/profile" element={<Profile toggleModal={toggleModal} />} />
                  <Route exact path="/error" element={<ErrorPage toggleModal={toggleModal} />} />
                </Routes>
              </Router>
            </>
          }
        </Modal>
        {!isOpen &&
          <button onClick={() => setIsOpen(true)} className="circle-button-container" id="circleButton">
            <p className='no-margin'><i className="fa fa-angle-left" style={{ fontSize: 35, width: '100%' }}></i></p>
          </button>
        }
      </Suspense>
    </>
  );
}

export default App;
