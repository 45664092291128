let conn=JSON.parse(localStorage.getItem('gret'))
export function initializeLocalStorage() {
    // console.log(conn)
    if(conn){
        conn=conn
    }
    else{
        conn={}
    }
}

export function get(key) {
    // console.log(conn[key])
    if (conn[key] === undefined || conn[key] === 'undefined') 
    {
       return undefined; 
    } 
    else if (conn[key] === null)
    {  
         return null; 
    } 
    else 
    { 
        return conn[key]; 
    }
}

export function set(key,value){
    conn[key] = value;
    localStorage.setItem('gret', JSON.stringify(conn));
} 

export function deletekey(key){
    if (key in conn) {
      delete conn[key];
      localStorage.setItem('gret', JSON.stringify(conn));
    }
}

export function is_available() {
    try {
      localStorage.setItem('gret', 'gret');
      localStorage.removeItem('gret');
      return true;
    } catch (error) { return false; }
  }

export function logoutClear() {
    delete('token');
  }


