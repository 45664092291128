

export const dark_green_1 = '#003929';
export const dark_green_2 = '#007251';
export const green = '#00AB79';
export const light_green_1 = '#00E4A2';
export const light_green_2 = '#72FFD6';
export const light_green_3 = '#43ffbb';
export const off_white_1 = '#EEEEF7';
export const off_white_2 = '#F5F5F5';
export let background_color = '#3F3D6A';
export let heading_color = '#272752';
export let input_color = '#0F133B';
export const dark_blue_4 = '#9c9cd0'
export const dark_blue_5 = '#3F60A0'
export let paragraph_color = '#9c9cd0';
export const dark_purple_1 = '#8282b4';
export let dark_gray_1 = '#3b3b3b';

export function setcolorValues(client_details_data) {
    background_color = client_details_data.light_color_1
    heading_color = client_details_data.dark_color_1
    input_color = client_details_data.dark_color_2
    paragraph_color = client_details_data.light_color_2
    dark_gray_1 = client_details_data.dark_color_1

    document.documentElement.style.setProperty('--font-size-head1', `${client_details_data.font_size_head1}px`);
    document.documentElement.style.setProperty('--font-size-head2', `${client_details_data.font_size_head2}px`);
    document.documentElement.style.setProperty('--font-size-para', `${client_details_data.font_size_para}px`);
    document.documentElement.style.setProperty('--font-family-head1', client_details_data.font_family_head1);
    document.documentElement.style.setProperty('--font-family-head2', client_details_data.font_family_head2);
    document.documentElement.style.setProperty('--font-family-para', client_details_data.font_family_para);
    document.documentElement.style.setProperty('--heading-color', client_details_data.dark_color_1);
    document.documentElement.style.setProperty('--input-color', client_details_data.dark_color_2);
    document.documentElement.style.setProperty('--background-color', client_details_data.light_color_1);
    document.documentElement.style.setProperty('--paragraph-color', client_details_data.light_color_2);
    document.documentElement.style.setProperty('--dark-gray-1', client_details_data.dark_color_1);
}

// export let background_color=client_details_data?client_details_data.light_color_1:'#3F3D6A';
// export let heading_color=client_details_data?client_details_data.dark_color_1:'#272752';
// export let input_color=client_details_data?client_details_data.dark_color_2:'#0F133B';
// export let paragraph_color=client_details_data?client_details_data.light_color_2:'#9c9cd0';
