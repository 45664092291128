import React from 'react'
import environment from '../Environments/Environmnet'
import { setcolorValues } from '../Styles/Colors'
import { get } from '../LocalStorage/LocalStorage'
import { userForbidden } from './ServiceValidations'

export const get_client_details = async () => {
    const client_unique_id = localStorage.getItem('client_unique_id')
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', },
        params: { client_unique_id: client_unique_id }
    };
    var url = `${environment.server_root}/api/client/get_client_details?client_unique_id=` + client_unique_id
    const response = await fetch(url, requestOptions);
    userForbidden(response)
    const data = await response.json();
    let client_details_data = data.data
    setcolorValues(client_details_data)
    return data;
}